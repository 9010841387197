define("ember-elsewhere/components/from-elsewhere", ["exports", "@ember/component", "@ember/application", "@ember/runloop", "rsvp", "@ember/service", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _application, _runloop, _rsvp, _service, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.initialized}}
    {{#let (get this.service.actives this.name) as |active| }}
      {{#if (has-block)}}
        {{yield active.lastObject.component active.lastObject.outsideParams}}
      {{else}}
        {{#let active.lastObject.component as |c|}}
          {{component (ensure-safe-component c) }}
        {{/let}}
      {{/if}}
    {{/let}}
  {{/if}}
  
  */
  {
    "id": "Qxgi88X9",
    "block": "[[[41,[30,0,[\"initialized\"]],[[[44,[[28,[37,2],[[30,0,[\"service\",\"actives\"]],[30,0,[\"name\"]]],null]],[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[30,1,[\"lastObject\",\"component\"]],[30,1,[\"lastObject\",\"outsideParams\"]]]],[1,\"\\n\"]],[]],[[[44,[[30,1,[\"lastObject\",\"component\"]]],[[[1,\"        \"],[46,[28,[37,6],[[30,2]],null],null,null,null],[1,\"\\n\"]],[2]]]],[]]]],[1]]]],[]],null]],[\"active\",\"c\",\"&default\"],false,[\"if\",\"let\",\"get\",\"has-block\",\"yield\",\"component\",\"ensure-safe-component\"]]",
    "moduleName": "ember-elsewhere/components/from-elsewhere.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_dec = (0, _service.inject)('ember-elsewhere'), (_class = class _class extends _component2.default {
    get name() {
      if (this.args.named) {
        throw new Error(`<FromElsewhere> takes a "@name=" parameter, not "@named="`);
      }
      return this.args.name ?? 'default';
    }

    // We don't yield any content on the very first render pass, because
    // we want to give any concurrent {{to-elsewhere}} components a chance
    // to declare their intentions first. This allows the components
    // inside us to see a meaningful initial value on their initial
    // render.

    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "service", _descriptor, this);
      _initializerDefineProperty(this, "initialized", _descriptor2, this);
      let promise = new _rsvp.Promise(resolve => {
        (0, _runloop.schedule)('afterRender', () => {
          this.initialized = true;
          resolve();
        });
      });
      let fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      if (fastboot && fastboot.isFastBoot) {
        fastboot.deferRendering(promise);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initialized", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class)));
});